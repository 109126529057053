import React, { useEffect, useState } from 'react';
import BaseFilterCard from './base-filter-card';
import { AiOutlineAim } from 'react-icons/ai';
import { useDispatch, useSelector } from 'react-redux';
import {
  setCharitableOptions,
  setCharitableOptionsEnabled,
  setEducationOptions,
  setEducationOptionsEnabled,
  setEngagementOptions,
  setEngagementOptionsEnabled,
  setFamilyOptions,
  setFamilyOptionsEnabled,
  setNodeOptionsFilter,
  setNodeOptionsFilterEnabled,
  setOrganizationalOptions,
  setOrgOptionsEnabled,
  setProfessionalOptions,
  setProfessionalOptionsEnabled,
  setVisibleConnections,
} from 'store/filters/actions';
import {
  familyOptions,
  organizationalOptions,
  professionalOptions,
  charitableOptions,
  educationalOptions,
  nodeOptions,
  engagementOptions,
  customFamilyGroupingOptions,
  FamilyGroupMapping,
  GetEdgeOptions,
} from './options';
import OptionsCardWrapper from './options-card';
import styles from './styles/filterCard.module.css';
import {
  selectCharitableOptionsEnabled,
  selectEducationOptionsEnabled,
  selectFamilyOptionsEnabled,
  selectNodeOptionsEnabled,
  selectOrganizationalOptionsEnabled,
  selectProfressionalOptionsEnabled,
  selectEngagementOptionsEnabled,
  selectShowVisibleConnections,
  selectTimeFilters,
  selectViewFilterUpdates,
  selectReset,
} from 'store/filters/selectors';
import { edgeColors } from 'utils/constants';
import ReactTooltip from 'react-tooltip';
import {
  CharitableIcon,
  EducationIcon,
  FamilyIcon,
  PersonalIcon,
  ProfessionalIcon,
  EngagementIcon,
} from 'common/icon/icon';
import useComponentVisible from 'utils/hooks';

// TODO: REWORK, too many features got added to this component leading to tech debt
const FilterCards = () => {
  const dispatch = useDispatch();
  const [selected, setSelected] = useState(null);
  const { ref, isVisible, setIsVisible } = useComponentVisible(false);
  const familyIsSelected = useSelector(selectFamilyOptionsEnabled);
  const professionalIsSelected = useSelector(selectProfressionalOptionsEnabled);
  const organizationalIsSelected = useSelector(
    selectOrganizationalOptionsEnabled,
  );
  const charitableIsSelected = useSelector(selectCharitableOptionsEnabled);
  const educationIsSelected = useSelector(selectEducationOptionsEnabled);
  const engagementIsSelected = useSelector(selectEngagementOptionsEnabled);
  const filterUpdates = useSelector(selectViewFilterUpdates);
  const nodeIsSelected = useSelector(selectNodeOptionsEnabled);
  const showVisible = useSelector(selectShowVisibleConnections);
  const timeFilters = useSelector(selectTimeFilters);
  const reset = useSelector(selectReset);
  const [family, setFamily] = useState(familyOptions);
  const [familyCustomOptions, setFamilyCustomOptions] = useState({
    ...customFamilyGroupingOptions,
  });
  const [familyAdvanced, setAdvancedFamily] = useState(false);
  const [professional, setProfessional] = useState(professionalOptions);
  const [organizational, setOrganizational] = useState(organizationalOptions);
  const [charitable, setCharitable] = useState(charitableOptions);
  const [education, setEducation] = useState(educationalOptions);
  const [engagement, setEngagement] = useState(engagementOptions);
  const [nodeFilters, setNodeFilters] = useState(nodeOptions);
  const [familyTimeFilter, setFamilyTimeFilter] = useState(
    timeFilters.familyOptionsTimeFilter,
  );
  const [professionalTimeFilter, setProfessionalTimeFilter] = useState(
    timeFilters.professionalOptionsTimeFilter,
  );
  const [organizationalTimeFilter, setOrganizationalTimeFilter] = useState(
    timeFilters.organizationalOptionsTimeFilter,
  );
  const [charitableTimeFilter, setCharitableTimeFilter] = useState(
    timeFilters.charitableOptionsTimeFilter,
  );
  const [educationTimeFilter, setEducationTimeFilter] = useState(
    timeFilters.educationOptionsTimeFilter,
  );
  const [engagementTimeFilter, setEngagementTimeFilter] = useState(
    timeFilters.engagementOptionsTimeFilter,
  );
  const resetOptions = GetEdgeOptions();
  const handleSelect = (selector) => {
    setSelected(selector);
    setIsVisible(true);
  };

  const close = () => {
    setSelected(null);
    setIsVisible(false);
  };

  const professionalFilters = ({ values, type, filterOut, timeFilter }) => {
    dispatch(setProfessionalOptions(values, type, filterOut, timeFilter));
  };

  const familyFilters = ({ values, type, filterOut, timeFilter }) => {
    dispatch(setFamilyOptions(values, type, filterOut, timeFilter));
  };

  const organizationalFilters = ({ values, type, filterOut, timeFilter }) => {
    dispatch(setOrganizationalOptions(values, type, filterOut, timeFilter));
  };

  const charitableFilters = ({ values, type, filterOut, timeFilter }) => {
    dispatch(setCharitableOptions(values, type, filterOut, timeFilter));
  };

  const educationFilters = ({ values, type, filterOut, timeFilter }) => {
    dispatch(setEducationOptions(values, type, filterOut, timeFilter));
  };

  const engagementFilters = ({ values, type, filterOut, timeFilter }) => {
    dispatch(setEngagementOptions(values, type, filterOut, timeFilter));
  };

  const dispatchNodeFilters = ({ values, type, filterOut, timeFilter }) => {
    dispatch(setNodeOptionsFilter(values, type, filterOut, timeFilter));
  };

  const professionalFiltersEnabled = (value) => {
    dispatch(setProfessionalOptionsEnabled(value));
  };

  const familyFiltersEnalbed = (value) => {
    dispatch(setFamilyOptionsEnabled(value));
  };

  const organizationalFiltersEnabled = (value) => {
    dispatch(setOrgOptionsEnabled(value));
  };

  const charitableFiltersEnabled = (value) => {
    dispatch(setCharitableOptionsEnabled(value));
  };

  const engagementFiltersEnabled = (value) => {
    dispatch(setEngagementOptionsEnabled(value));
  };

  const educationFiltersEnabled = (value) => {
    dispatch(setEducationOptionsEnabled(value));
  };

  const nodeFiltersEnabled = (value) => {
    dispatch(setNodeOptionsFilterEnabled(value));
  };

  const clearProfessionalFilters = () => {
    setProfessional(professionalOptions);
    setProfessionalTimeFilter({ start: null, end: null });
    setProfessional(resetOptions.getProfessionalOptions());
  };
  const clearFamilyFilters = () => {
    setFamilyTimeFilter({ start: null, end: null });
    setFamilyCustomOptions(customFamilyGroupingOptions);
    setFamily(resetOptions.getFamilyOptions());
  };

  const clearOrganizationalFilters = () => {
    setOrganizationalTimeFilter({ start: null, end: null });
    setOrganizational(resetOptions.getOrganizationalOptions());
  };

  const clearCharitableFilters = () => {
    setCharitableTimeFilter({ start: null, end: null });
    setCharitable(resetOptions.getCharitableOptions());
  };

  const clearEducationFilters = () => {
    setEducationTimeFilter({ start: null, end: null });
    setEducation(resetOptions.getEducationalOptions());
  };

  const clearEngagementFilters = () => {
    setEngagementTimeFilter({ start: null, end: null });
    setEngagement(resetOptions.getEngagementOptions());
  };

  const clearNodeFilters = () => {
    setNodeFilters(resetOptions.getNodeOptions());
  };
  let style = {};
  if (showVisible) {
    style = {
      border: '1px solid #fff',
      background: '#000',
      color: '#fff',
    };
  }
  const setShowVisible = (e) => {
    e?.preventDefault();
    dispatch(setVisibleConnections(!showVisible));
  };

  useEffect(() => {
    const { viewFilterUpdates, ...types } = filterUpdates;
    const resetOptions = GetEdgeOptions();
    if (viewFilterUpdates !== undefined && viewFilterUpdates !== 'All') {
      if (
        viewFilterUpdates.familyFilterType &&
        viewFilterUpdates?.familyOptions?.length > 0
      ) {
        let newFamily = { ...family };
        viewFilterUpdates.familyOptions.forEach((element) => {
          if (newFamily[element]) {
            newFamily[element].isSelected = viewFilterUpdates.familyFilterType;
          }
        });
        setFamily(newFamily);
      } else {
        setFamily(resetOptions.getFamilyOptions());
      }
      if (
        viewFilterUpdates.professionalFilterType &&
        viewFilterUpdates?.professionalOptions?.length > 0
      ) {
        let newProfessional = { ...professional };
        viewFilterUpdates.professionalOptions.forEach((element) => {
          if (newProfessional[element]) {
            newProfessional[element].isSelected =
              viewFilterUpdates.professionalFilterType ||
              types.professionalFilterType;
          }
        });
        setProfessional(newProfessional);
      } else {
        setProfessional(resetOptions.getProfessionalOptions());
      }
      if (
        viewFilterUpdates.organizationalFilterType &&
        viewFilterUpdates?.organizationalOptions?.length > 0
      ) {
        let newOrg = { ...organizational };
        viewFilterUpdates.organizationalOptions.forEach((element) => {
          if (newOrg[element]) {
            newOrg[element].isSelected =
              viewFilterUpdates.organizationalFilterType ||
              types.organizationalFilterType;
          }
        });
        setOrganizational(newOrg);
      } else {
        setOrganizational(resetOptions.getOrganizationalOptions());
      }
      if (
        viewFilterUpdates.charitableFilterType &&
        viewFilterUpdates?.charitableOptions?.length > 0
      ) {
        let newCharitable = { ...charitable };
        viewFilterUpdates.charitableOptions.forEach((element) => {
          if (newCharitable[element]) {
            newCharitable[element].isSelected =
              viewFilterUpdates.charitableFilterType ||
              types.charitableFilterType;
          }
        });
        setCharitable(newCharitable);
      } else {
        setCharitable(resetOptions.getCharitableOptions());
      }
      if (
        viewFilterUpdates.educationFilterType &&
        viewFilterUpdates?.educationOptions?.length > 0
      ) {
        let newEducation = { ...education };
        viewFilterUpdates.educationOptions.forEach((element) => {
          if (newEducation[element]) {
            newEducation[element].isSelected =
              viewFilterUpdates.educationFilterType ||
              types.educationFilterType;
          }
        });
        setEducation(newEducation);
      } else {
        setEducation(resetOptions.getEducationalOptions());
      }
      if (
        viewFilterUpdates.engagementFilterType &&
        viewFilterUpdates?.engagementOptions?.length > 0
      ) {
        let newEngagement = { ...engagement };
        viewFilterUpdates.engagementOptions.forEach((element) => {
          if (newEngagement[element]) {
            newEngagement[element].isSelected =
              viewFilterUpdates.engagementFilterType ||
              types.engagementFilterType;
          }
        });
        setEngagement(newEngagement);
      } else {
        setEngagement(resetOptions.getEngagementOptions());
      }

      // always set node options
      let newNodeOptions = { ...nodeOptions };
      viewFilterUpdates.nodeFilterOptions.forEach((element) => {
        if (newNodeOptions[element]) {
          newNodeOptions[element].isSelected =
            viewFilterUpdates.nodeFilterType || types.nodeFilterType;
        }
      });
      setNodeFilters(newNodeOptions);
    } else if (viewFilterUpdates === 'All') {
      setFamily(resetOptions.getFamilyOptions());
      setProfessional(resetOptions.getProfessionalOptions());
      setOrganizational(resetOptions.getOrganizationalOptions());
      setCharitable(resetOptions.getCharitableOptions());
      setEducation(resetOptions.getEducationalOptions());
      setEngagement(resetOptions.getEngagementOptions());
      setNodeFilters(resetOptions.getNodeOptions());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterUpdates.viewFilterUpdates]);

  useEffect(() => {
    if (reset !== 0) {
      const resetOptions = GetEdgeOptions();
      setFamily(resetOptions.getFamilyOptions());
      setFamilyCustomOptions(customFamilyGroupingOptions);
      setProfessional(resetOptions.getProfessionalOptions());
      setOrganizational(resetOptions.getOrganizationalOptions());
      setCharitable(resetOptions.getCharitableOptions());
      setEducation(resetOptions.getEducationalOptions());
      setEngagement(resetOptions.getEngagementOptions());
      setNodeFilters(resetOptions.getNodeOptions());
    }
  }, [reset]);

  const showVisibleText = showVisible ? 'Show Orphaned' : 'Hide Orphaned';
  const keys = Object.keys(edgeColors);
  return (
    <div className={styles.filter_cards_wrapper} ref={ref}>
      <div className={styles.filters}>
        <BaseFilterCard
          value="Nodes"
          isChecked={nodeIsSelected}
          setEnabled={nodeFiltersEnabled}
          setSelected={handleSelect}
          selector={5}
          isOpen={selected === 5 && isVisible}
          color="#000"
        />
        <BaseFilterCard
          value="Family"
          isChecked={familyIsSelected}
          setEnabled={familyFiltersEnalbed}
          setSelected={handleSelect}
          selector={0}
          isOpen={selected === 0 && isVisible}
          color={edgeColors.family}
        />
        <BaseFilterCard
          value="Personal"
          isChecked={professionalIsSelected}
          setEnabled={professionalFiltersEnabled}
          setSelected={handleSelect}
          selector={1}
          isOpen={selected === 1 && isVisible}
          color={edgeColors.personal}
        />
        <BaseFilterCard
          value="Professional"
          isChecked={organizationalIsSelected}
          setEnabled={organizationalFiltersEnabled}
          setSelected={handleSelect}
          selector={2}
          isOpen={selected === 2 && isVisible}
          color={edgeColors.professional}
        />
        <BaseFilterCard
          value="Charitable"
          isChecked={charitableIsSelected}
          setEnabled={charitableFiltersEnabled}
          setSelected={handleSelect}
          selector={3}
          isOpen={selected === 3 && isVisible}
          color={edgeColors.charitable}
        />
        <BaseFilterCard
          value="Education"
          isChecked={educationIsSelected}
          setEnabled={educationFiltersEnabled}
          setSelected={handleSelect}
          selector={4}
          isOpen={selected === 4 && isVisible}
          color={edgeColors.education}
        />
        <BaseFilterCard
          value="Engagement"
          isChecked={engagementIsSelected}
          setEnabled={engagementFiltersEnabled}
          setSelected={handleSelect}
          selector={6}
          isOpen={selected === 6 && isVisible}
          color={edgeColors.engagement}
        />
        <div
          data-tip
          data-for="visible-connections-button"
          className={styles.visible_wrapper}
          style={style}
          onClick={setShowVisible}
        >
          <AiOutlineAim />
        </div>
        <ReactTooltip
          id="visible-connections-button"
          place="top"
          effect="solid"
        >
          {showVisibleText}
        </ReactTooltip>
      </div>
      {isVisible && selected === 5 && (
        <OptionsCardWrapper
          options={nodeFilters}
          setOptions={setNodeFilters}
          dispatchFilters={dispatchNodeFilters}
          close={close}
          headerText="Set Entity Type Filters"
          clear={clearNodeFilters}
          color="undefined"
        />
      )}
      {isVisible && selected === 0 && (
        <OptionsCardWrapper
          timeFilterWrapper={{
            timeFilter: familyTimeFilter,
            setTimeFilter: setFamilyTimeFilter,
          }}
          options={family}
          setOptions={setFamily}
          setGroupOptions={setFamilyCustomOptions}
          dispatchFilters={familyFilters}
          close={close}
          icon={<FamilyIcon className={styles.family_icon} />}
          headerText="Family Options"
          clear={clearFamilyFilters}
          color={keys[0]}
          customGrouping={familyCustomOptions}
          customGroupingMapping={FamilyGroupMapping}
          showAdvanced={familyAdvanced}
          setShowAdvanced={setAdvancedFamily}
        />
      )}
      {isVisible && selected === 1 && (
        <OptionsCardWrapper
          timeFilterWrapper={{
            timeFilter: professionalTimeFilter,
            setTimeFilter: setProfessionalTimeFilter,
          }}
          options={professional}
          setOptions={setProfessional}
          dispatchFilters={professionalFilters}
          close={close}
          icon={<PersonalIcon className={styles.professional_icon} />}
          headerText="Personal Options"
          clear={clearProfessionalFilters}
          color={keys[1]}
        />
      )}
      {isVisible && selected === 2 && (
        <OptionsCardWrapper
          timeFilterWrapper={{
            timeFilter: organizationalTimeFilter,
            setTimeFilter: setOrganizationalTimeFilter,
          }}
          options={organizational}
          setOptions={setOrganizational}
          dispatchFilters={organizationalFilters}
          close={close}
          icon={<ProfessionalIcon className={styles.organization_icon} />}
          headerText="Professional Options"
          clear={clearOrganizationalFilters}
          color={keys[2]}
        />
      )}
      {isVisible && selected === 3 && (
        <OptionsCardWrapper
          timeFilterWrapper={{
            timeFilter: charitableTimeFilter,
            setTimeFilter: setCharitableTimeFilter,
          }}
          options={charitable}
          setOptions={setCharitable}
          dispatchFilters={charitableFilters}
          close={close}
          icon={<CharitableIcon className={styles.charitable_icon} />}
          headerText="Charitable Options"
          clear={clearCharitableFilters}
          color={keys[3]}
        />
      )}
      {isVisible && selected === 4 && (
        <OptionsCardWrapper
          timeFilterWrapper={{
            timeFilter: educationTimeFilter,
            setTimeFilter: setEducationTimeFilter,
          }}
          options={education}
          setOptions={setEducation}
          dispatchFilters={educationFilters}
          close={close}
          icon={<EducationIcon className={styles.education_icon} />}
          headerText="Education Options"
          clear={clearEducationFilters}
          color={keys[4]}
        />
      )}
      {isVisible && selected === 6 && (
        <OptionsCardWrapper
          timeFilterWrapper={{
            timeFilter: engagementTimeFilter,
            setTimeFilter: setEngagementTimeFilter,
          }}
          options={engagement}
          setOptions={setEngagement}
          dispatchFilters={engagementFilters}
          close={close}
          icon={<EngagementIcon />}
          headerText="Engagement Options"
          clear={clearEngagementFilters}
          color={keys[5]}
        />
      )}
    </div>
  );
};

export default FilterCards;
