import React, { useState } from 'react';
import DropDown from '../../drop-down';
import styles from './prospect.module.css';
import Item from './item';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectFundPurpose,
  selectIsFundPurpose,
} from 'store/filters/selectors';
import { resetFundPurpose } from 'store/filters/actions';
import { getInitialFundPurposeState } from 'store/filters/constants';

const FundPurposeFilter = ({
  local = undefined,
  setLocal = undefined,
  useLocal = false,
}) => {
  const [open, setOpen] = useState(false);
  const currentFundPurpose = useSelector(selectFundPurpose);
  const isFundPurpose = useSelector(selectIsFundPurpose);
  const dispatch = useDispatch();
  const clear = () => {
    dispatch(resetFundPurpose(getInitialFundPurposeState()));
  };
  const handleToggle = () => {
    setOpen(!open);
  };

  const values = useLocal ? local : currentFundPurpose;
  return (
    <div className={!open ? 'filter_by_wrapper' : 'filter_by_open_wrapper'}>
      <DropDown
        filterText="Fund Types"
        onClick={handleToggle}
        open={open}
        clearInsteadOfIcon={isFundPurpose}
        clear={clear}
      />
      {open && (
        <div className={styles.filter_grid}>
          {Object.keys(values).map((x) => {
            const filter = values[x];
            return (
              <Item
                key={x}
                prospectKey={x}
                value={filter.value}
                isSelected={filter.isSelected}
                useLocal={useLocal}
                setLocal={setLocal}
              />
            );
          })}
        </div>
      )}
    </div>
  );
};

export default FundPurposeFilter;
