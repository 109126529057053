import React from 'react';
import { AiOutlineQuestionCircle } from 'react-icons/ai';
import { useDispatch, useSelector } from 'react-redux';
import { showFeedbackModal } from 'store/core/actions';
import styles from './footer.module.css';
import { selectUsername } from 'store/core/selectors';
import html2canvas from 'html2canvas';

export const Footer = ({
  rootRef,
  isCollapsed = false,
  dataDate = undefined,
}) => {
  const dispatch = useDispatch();
  const openFeedbackModal = (e) => {
    html2canvas(rootRef.current).then((canvas) => {
      const imageUrl = canvas.toDataURL();
      dispatch(showFeedbackModal(imageUrl));
    });
  };
  return (
    <div
      className={`${styles.root} ${!isCollapsed && 'flex_align_items_start'}`}
    >
      <div onClick={openFeedbackModal} className={styles.feedBackButton}>
        <div className={styles.star_icon}>
          <AiOutlineQuestionCircle />
        </div>
        <p className={styles.feedback_text}>Give Feedback</p>
      </div>
      <CurrentUser isCollapsed={isCollapsed} />
      {dataDate && <div className={styles.dataDate}>Data as of {dataDate}</div>}
    </div>
  );
};

const CurrentUser = ({ isCollapsed = false }) => {
  const { username, initials } = useSelector(selectUsername);

  return (
    <div className={styles.root_button}>
      <div className={styles.user}>
        <div
          className={
            !isCollapsed ? styles.user_names_active : styles.user_names
          }
        >
          <div className={styles.badge}>{initials.toUpperCase()}</div>
          {!isCollapsed && <div className={styles.name_wrap}>{username}</div>}
        </div>
      </div>
    </div>
  );
};

export default Footer;
