import React, { useEffect, useState } from 'react';
import { BsPersonFill, BsFillLockFill } from 'react-icons/bs';
import { MdVisibility, MdVisibilityOff, MdErrorOutline } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { selectToken } from 'store/core/selectors';
import { setIsLoading, setLogin } from 'store/login/actions';
import { selectError, selectIsLoading } from 'store/login/selectors';
import login from '../../images/login.svg';
import logo from '../../images/logo.jpg';
import './login.css';

const Login = () => {
  const [username, setUsername] = useState('');
  const [pass, setPass] = useState('');
  const [showPass, setShowPass] = useState('');
  const error = useSelector(selectError);
  const isLoading = useSelector(selectIsLoading);
  const token = useSelector(selectToken);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onSubmit = (e) => {
    if (e && e.preventDefault) e.preventDefault();
    dispatch(setLogin(username, pass));
  };

  const onShowPassword = () => {
    setShowPass(true);
  };

  const onHidePassword = () => {
    setShowPass(false);
  };

  const handleEmailOnChange = (e) => {
    if (e && e.preventDefault) e.preventDefault();
    setUsername(e.target.value);
  };

  const handlePassOnChange = (e) => {
    if (e && e.preventDefault) e.preventDefault();
    setPass(e.target.value);
  };

  const handleSSOLogin = (e) => {
    if (e && e.preventDefault) e.preventDefault();
    window.location.href = `${process.env.REACT_APP_BASE_API_URL}/api/token/login`;
  };

  useEffect(() => {
    if (token) {
      navigate('/');
      setIsLoading(false);
    }
  }, [token, navigate]);

  const passwordInputType = showPass ? 'text' : 'password';
  return (
    <div className="login-wrapper">
      <div className="login-left-section">
        <div className="login-login-form-wrapper">
          <img src={logo} alt="" />
          <h1 className="login-header">Login</h1>
          <p className="login-subheader">Welcome Back!</p>
           <div className="login-single-sign-on-wrapper">
            <button
              type="button"
              className="base-button login-button-single-sign-on"
              onClick={handleSSOLogin}
            >
              <span>Login with SSO</span>
            </button>
          </div>
          <div className="login-divider-wrapper">
            <div className="login-divider-left"></div>
            <div className="login-divider-text">OR</div>
            <div className="login-divider-right"></div>
          </div> 
          <form className="login-form" onSubmit={onSubmit}>
            <div className="login-email-wrapper">
              <label className="login-label">Email</label>
              <div className="login-email-input-wrapper">
                <input
                  className="login-input"
                  type="text"
                  id="email"
                  placeholder="Enter your email"
                  required
                  value={username}
                  onChange={handleEmailOnChange}
                />
                <BsPersonFill className="login-person-icon" />
              </div>
              {/* {showEmailError && <span className="login-input-error">Email is required</span>} */}
            </div>
            <div className="login-password-wrapper">
              <label className="login-label">Password</label>
              <div className="login-password-input-wrapper">
                <input
                  className="login-input"
                  type={passwordInputType}
                  id="password"
                  placeholder="Enter your password"
                  required
                  value={pass}
                  onChange={handlePassOnChange}
                />
                <BsFillLockFill className="login-lock-icon" />
                {!showPass && (
                  <MdVisibility
                    className="login-show-password-icon"
                    onClick={onShowPassword}
                  />
                )}
                {showPass && (
                  <MdVisibilityOff
                    className="login-show-password-icon"
                    onClick={onHidePassword}
                  />
                )}
              </div>
              {/* {showPassError && <span classNdivame="login-input-error">Password is required</span>} */}
            </div>
            {/* <div className="login-remember-me-wrapper">
                            <div className="login-remember-me">
                                <input type="checkbox" className="login-remember-me-input" />
                                <p className="login-remember-me-text ">Remember me</p>
                            </div>
                                <p className="login-forgot-password">Forgot Password?</p>
                        </div> */}
            {error && (
              <div className="login-error-wrapper">
                <MdErrorOutline className="login-error-icon" />
                <div className="login-error-text">{error}</div>
              </div>
            )}
            <button
              type="submit"
              className="base-button login-button"
              disabled={username.length === 0 || pass.length === 0}
            >
              {!isLoading && (
                <span style={{ visibility: isLoading ? 'hidden' : 'visible' }}>
                  Login
                </span>
              )}
              {isLoading && (
                <div
                  className="lds-ring"
                  style={{ visibility: isLoading ? 'visible' : 'hidden' }}
                >
                  <div></div>
                </div>
              )}
            </button>
          </form>
        </div>
      </div>
      <div className="login-right-section">
        <div className="login-image">
          <img className="login-img" src={login} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Login;
