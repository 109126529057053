import {
  CLEAR_NODE_FILTERS,
  SET_CONSTITUENCY_FILTER,
  REMOVE_REGION_FILTER,
  SET_AFFILIATION_FILTER,
  SET_ENGAGEMENT_OPTIONS_FILTER_ENABLED,
  SET_ENGAGEMENT_OPTIONS_FILTER,
  SET_CHARITABLE_OPTIONS_FILTER,
  SET_CHARITABLE_OPTIONS_FILTER_ENABLED,
  SET_DEGREES_OF_SEPARATION,
  SET_EDUCATION_OPTIONS_FILTER,
  SET_EDUCATION_OPTIONS_FILTER_ENABLED,
  SET_FAMILY_OPTIONS_FILTER,
  SET_FUND_PURPOSE_FILTER,
  SET_FAMILY_OPTIONS_FILTER_ENABLED,
  SET_NODE_OPTIONS_FILTER,
  SET_NODE_OPTIONS_FILTER_ENABLED,
  SET_ORGANIZATIONAL_OPTIONS_FILTER,
  SET_ORGANIZATIONAL_OPTIONS_FILTER_ENABLED,
  SET_PROFESSIONAL_OPTIONS_FILTER,
  SET_PROFESSIONAL_OPTIONS_FILTER_ENABLED,
  SET_PROSPECT_STATUS_FILTER,
  SET_REGION_FILTER,
  SET_TOTAL_CONNECTIONS,
  REMOVE_CONSTITUENCY_FILTER,
  SET_VISIBLE_CONNECTIONS,
  SET_SHORTEST_PATH_NODE_ID,
  SET_VIEW_FILTERS,
  SET_MIN_LIFETIME_GIVING,
  SET_MAX_LIFETIME_GIVING,
  ADD_HIDDEN_NODE,
  RESET_HIDDEN_NODES,
  CLEAR_HIDDEN_NODE,
  SET_NODE_SIZING_TYPE,
  SET_VIEW_LOADING,
  TOGGLE_SHOW_DECEASED_CONNECTIONS,
  SET_CONTEXT_FILTERS,
  DELETE_CONTEXT_FILTERS,
  SET_AGE_FILTER,
  RESET_FUND_PURPOSE_FILTER,
} from './constants';

export const setDegreesOfSeparation = (degreesOfSeparation) => {
  return {
    type: SET_DEGREES_OF_SEPARATION,
    degreesOfSeparation,
  };
};

export const setContextFilters = (id, nodeFilters, edgeFilters) => {
  return {
    type: SET_CONTEXT_FILTERS,
    id,
    nodeFilters,
    edgeFilters,
  };
};

export const deleteContextFilters = (id) => {
  return {
    type: DELETE_CONTEXT_FILTERS,
    id,
  };
};

export const setNodeSizingType = (sizing) => {
  return {
    type: SET_NODE_SIZING_TYPE,
    sizing,
  };
};

export const setProspectStatus = (key, isSelected) => {
  return {
    type: SET_PROSPECT_STATUS_FILTER,
    key,
    isSelected,
  };
};

export const setFundPurpose = (key, isSelected) => {
  return {
    type: SET_FUND_PURPOSE_FILTER,
    key,
    isSelected,
  };
};

export const resetFundPurpose = () => {
  return {
    type: RESET_FUND_PURPOSE_FILTER,
  };
};

export const setFamilyOptions = (
  options,
  filterType,
  filterOut,
  timeFilter,
) => {
  return {
    type: SET_FAMILY_OPTIONS_FILTER,
    options,
    filterOut,
    filterType,
    timeFilter,
  };
};

export const setProfessionalOptions = (
  options,
  filterType,
  filterOut,
  timeFilter,
) => {
  return {
    type: SET_PROFESSIONAL_OPTIONS_FILTER,
    options,
    filterOut,
    filterType,
    timeFilter,
  };
};

export const setOrganizationalOptions = (
  options,
  filterType,
  filterOut,
  timeFilter,
) => {
  return {
    type: SET_ORGANIZATIONAL_OPTIONS_FILTER,
    options,
    filterOut,
    filterType,
    timeFilter,
  };
};

export const setCharitableOptions = (
  options,
  filterType,
  filterOut,
  timeFilter,
) => {
  return {
    type: SET_CHARITABLE_OPTIONS_FILTER,
    options,
    filterOut,
    filterType,
    timeFilter,
  };
};

export const setEducationOptions = (
  options,
  filterType,
  filterOut,
  timeFilter,
) => {
  return {
    type: SET_EDUCATION_OPTIONS_FILTER,
    options,
    filterOut,
    filterType,
    timeFilter,
  };
};

export const setEngagementOptions = (
  options,
  filterType,
  filterOut,
  timeFilter,
) => {
  return {
    type: SET_ENGAGEMENT_OPTIONS_FILTER,
    options,
    filterOut,
    filterType,
    timeFilter,
  };
};

export const setNodeOptionsFilter = (
  options,
  filterType,
  filterOut,
  timeFilter,
) => {
  return {
    type: SET_NODE_OPTIONS_FILTER,
    options,
    filterOut,
    filterType,
  };
};

export const setEducationOptionsEnabled = (value) => {
  return {
    type: SET_EDUCATION_OPTIONS_FILTER_ENABLED,
    value,
  };
};

export const setEngagementOptionsEnabled = (value) => {
  return {
    type: SET_ENGAGEMENT_OPTIONS_FILTER_ENABLED,
    value,
  };
};

export const setOrgOptionsEnabled = (value) => {
  return {
    type: SET_ORGANIZATIONAL_OPTIONS_FILTER_ENABLED,
    value,
  };
};
export const setFamilyOptionsEnabled = (value) => {
  return {
    type: SET_FAMILY_OPTIONS_FILTER_ENABLED,
    value,
  };
};
export const setNodeOptionsFilterEnabled = (value) => {
  return {
    type: SET_NODE_OPTIONS_FILTER_ENABLED,
    value,
  };
};

export const setProfessionalOptionsEnabled = (value) => {
  return {
    type: SET_PROFESSIONAL_OPTIONS_FILTER_ENABLED,
    value,
  };
};

export const setCharitableOptionsEnabled = (value) => {
  return {
    type: SET_CHARITABLE_OPTIONS_FILTER_ENABLED,
    value,
  };
};

export const setRegionFilter = (value) => {
  return {
    type: SET_REGION_FILTER,
    value,
  };
};

export const removeRegionFilter = (value) => {
  return {
    type: REMOVE_REGION_FILTER,
    value,
  };
};

export const clearNodeFilters = () => {
  return {
    type: CLEAR_NODE_FILTERS,
  };
};

export const setTotalConnections = (value) => {
  return {
    type: SET_TOTAL_CONNECTIONS,
    value,
  };
};

export const setAffiliationFilter = (value) => {
  return {
    type: SET_AFFILIATION_FILTER,
    value,
  };
};
export const setConstituencyFilter = (value) => {
  return {
    type: SET_CONSTITUENCY_FILTER,
    value,
  };
};
export const removeConstituencyFilter = (value) => {
  return {
    type: REMOVE_CONSTITUENCY_FILTER,
    value,
  };
};

export const setMaxLifetimeGiving = (max) => {
  return {
    type: SET_MAX_LIFETIME_GIVING,
    payload: max,
  };
};
export const setMinLifetimeGiving = (min) => {
  return {
    type: SET_MIN_LIFETIME_GIVING,
    payload: min,
  };
};

export const setVisibleConnections = (value) => {
  return {
    type: SET_VISIBLE_CONNECTIONS,
    value,
  };
};

export const setShortestPathNodeId = (value) => {
  return {
    type: SET_SHORTEST_PATH_NODE_ID,
    value,
  };
};

export const setViewFilters = (viewType, moreThanOneContext = true) => {
  return {
    type: SET_VIEW_FILTERS,
    viewType,
    moreThanOneContext,
  };
};

export const addHiddenNode = (node) => {
  return {
    type: ADD_HIDDEN_NODE,
    node, // nodeid, data
  };
};

export const removeHiddenNode = (nodeId) => {
  return {
    type: CLEAR_HIDDEN_NODE,
    nodeId,
  };
};

export const clearHiddenNodes = () => {
  return {
    type: RESET_HIDDEN_NODES,
  };
};

export const setViewLoading = (context) => {
  return {
    type: SET_VIEW_LOADING,
    context,
  };
};

export const setToggleShowDeceased = (newValue) => {
  return {
    type: TOGGLE_SHOW_DECEASED_CONNECTIONS,
    payload: newValue,
  };
};

export const setAgeFilter = (start, end) => {
  return {
    type: SET_AGE_FILTER,
    start,
    end,
  };
};
