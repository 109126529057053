export const SET_NL_MESSAGE = 'SET_NL_MESSAGE';
export const REMOVE_NL_MESSAGE = 'REMOVE_NL_MESSAGE';

export const setNlMessage = (message, contexts) => {
  return {
    type: SET_NL_MESSAGE,
    message,
    contexts,
  };
};
