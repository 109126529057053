import React from 'react';
import styles from './chat.module.css';

const Nodes = 'Nodes';
const Regions = 'Region';

export const SEARCH_TYPES = [
  { id: Nodes, label: 'Nodes', icon: '📁' },
  { id: Regions, label: 'Regions', icon: '📁' }
];

const SearchModalForChat = ({ 
  onClose, 
  onSelect, 
  searchType, 
  searchResults = [], 
  isLoading,
  selectedIndex = 0
}) => {
  if (!searchType) {
    return (
      <div className={styles.modal}>
        <div className={styles.typeList}>
          {SEARCH_TYPES.map((type, index) => (
            <div 
              key={type.id}
              data-index={index}
              className={`${styles.typeItem} ${
                index === selectedIndex ? styles.highlighted : ''
              }`}
              onClick={() => onSelect(type)}
            >
              <span className={styles.folderIcon}>{type.icon}</span> {type.label}
            </div>
          ))}
        </div>
      </div>
    );
  }

  return (
    <div className={styles.modal}>
      <div className={styles.typeList}>
        {searchResults.map((result, index) => (
          <div 
            key={result.id || index}
            data-index={index}
            className={`${styles.typeItem} ${
              index === selectedIndex ? styles.highlighted : ''
            }`}
            onClick={() => onSelect(result)}
          >
            {result.displayValue || result.metroRegion}
          </div>
        ))}
        {searchResults.length === 0 && !isLoading && searchType && (
          <div className={styles.noResults}>Type to search in {searchType.label}</div>
        )}
        {isLoading && (
          <div className={styles.noResults}>Searching...</div>
        )}
      </div>
    </div>
  );
};

export default SearchModalForChat;
