import { decodeJWT } from 'utils/hooks';
import {
  CANCEL_SEARCH,
  FEEBACK_MODAL,
  FEEDBACK_ERROR,
  RESET_COMPLETE_GRAPH,
  SET_CONNECTION,
  SET_TOKEN,
  INDIVDUAL_FILTERS,
} from './constants';

export const initialState = {
  token: '',
  expires: '',
  username: '',
  connection: null,
  showFeedbackModal: false,
  feedbackOption: null,
  feedbackError: '',
  cancelSearch: false,
  showIndividualFilters: undefined,
  feedbackImageUrl: null,
};

export const coreReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_TOKEN: {
      const { payload } = decodeJWT(action.token);
      return {
        ...state,
        token: action.token,
        expires: action.expires,
        username: payload.name,
        email: payload.email,
      };
    }
    case SET_CONNECTION: {
      return { ...state, connection: action.connection };
    }
    case FEEBACK_MODAL: {
      let op = action.option;
      let imageUrl = action.imageUrl;
      if (!action.payload) {
        op = null;
      }
      return {
        ...state,
        showFeedbackModal: action.payload,
        feedbackError: '',
        feedbackOption: op,
        ...(imageUrl && { feedbackImageUrl: imageUrl }),
      };
    }
    case FEEDBACK_ERROR: {
      return { ...state, feedbackError: action.payload };
    }
    case RESET_COMPLETE_GRAPH: {
      return { ...state, showFeedbackModal: false, feedbackError: '' };
    }
    case CANCEL_SEARCH: {
      return { ...state, cancelSearch: action.payload };
    }
    case INDIVDUAL_FILTERS: {
      return { ...state, showIndividualFilters: action.payload };
    }
    default:
      return state;
  }
};

export default coreReducer;
