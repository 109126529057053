import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './containers/app';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import configureStore from 'store/configureStore';
import './global.css';
import { EdgeOptionsSetup } from 'common/edge-filter-cards/options';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';

const appInsights = new ApplicationInsights({
  config: {
    connectionString: process.env.REACT_APP_APP_INSIGHTS_CONNECTION_STRING,
    enableAutoRouteTracking: true,
  },
});
appInsights.loadAppInsights();

const store = configureStore();
EdgeOptionsSetup();
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
