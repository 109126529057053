import styles from './contextfiltersmodal.module.css';
import AfilliationFilter from 'common/filters/affiliation-unit';
import ProspectFilter from 'common/filters/prospect-filter';
import LifetimeGivingFilter from 'common/filters/lifetime-giving';
import ConstituencyTypeFilter from 'common/filters/constituency-type';
import TotalConnectionsFilter from 'common/filters/total-connections';
import RegionFilter from 'common/filters/region-filter';
import decease_styles from '../filters/show-deceased/deceased.module.css';
import NodeSizing from 'common/filters/node-sizing';
import DegreesOfSeparationFilter from 'common/filters/degrees-separation';
import AgeFilter from 'common/filters/age-filter';

export const NodeFiltersPanel = ({
  affiliation,
  setAffilations,
  prospectStatus,
  updateLocalProspectStatus,
  max,
  min,
  setMaxLocal,
  setMinLocal,
  maxAge,
  setMaxAgeLocal,
  minAge,
  setMinAgeLocal,
  constituency,
  clearConstituencyValue,
  setConstituencyValue,
  connections,
  setTotalConnections,
  regions,
  clearRegionValue,
  setRegionsValue,
  setNodeSizing,
  nodeSizing,
  deceased,
  setDeceased,
  degrees,
  setDegrees,
  age,
  setAge,
}) => {
  return (
    <div className={styles.panel}>
      <AfilliationFilter
        local={affiliation}
        setLocal={setAffilations}
        useLocal
      />
      <ProspectFilter
        local={prospectStatus}
        useLocal
        setLocal={updateLocalProspectStatus}
      />
      <LifetimeGivingFilter
        maxLocal={max}
        minLocal={min}
        useLocal
        setMaxLocal={setMaxLocal}
        setMinLocal={setMinLocal}
      />
      <AgeFilter
        maxLocal={maxAge}
        minLocal={minAge}
        useLocal
        setMaxLocal={setMaxAgeLocal}
        setMinLocal={setMinAgeLocal}
      />
      <ConstituencyTypeFilter
        useLocal
        local={constituency}
        setClearLocal={clearConstituencyValue}
        setLocal={setConstituencyValue}
      />
      <TotalConnectionsFilter
        local={connections}
        useLocal
        setLocal={setTotalConnections}
      />
      <RegionFilter
        useLocal
        local={regions}
        setClearLocal={clearRegionValue}
        setLocal={setRegionsValue}
      />
      <NodeSizing useLocal setLocal={setNodeSizing} local={nodeSizing} />
      <ShowDeceasedFilter local={deceased} setLocal={setDeceased} />
      <DegreesOfSeparationFilter
        useLocal
        local={degrees}
        setLocal={setDegrees}
      />
    </div>
  );
};

const ShowDeceasedFilter = ({ setLocal, local }) => {
  const toggle = (e) => {
    setLocal(!local);
  };

  return (
    <div className={decease_styles.wrapper}>
      <div className={decease_styles.drop_down}>
        <div className={decease_styles.name}>Show Deceased</div>
        <input
          checked={local}
          className={decease_styles['react-switch-checkbox']}
          id={`react-switch-new`}
          type="checkbox"
        />
        <label
          onClick={toggle}
          style={{ background: local && '#38b2d9' }}
          className={decease_styles['react-switch-label']}
          htmlFor={`react-switch-new`}
        >
          <span className={decease_styles[`react-switch-button`]} />
        </label>
      </div>
    </div>
  );
};

export default NodeFiltersPanel;
