import { nodeType, totalConnectionsFilterMax } from './constants';

export const canAddProspect = (item, prospectFilter) => {
  if (prospectFilter.length < 1) return true;
  if (!item) return false;
  var type = item.data.nodeType;
  if (type !== nodeType.Person && type !== nodeType.Organization) return false;
  let prospectStatus = '';
  if (type === nodeType.Person) {
    prospectStatus = item.data?.prospectStatus;
  } else {
    prospectStatus = item.data.rating;
  }
  return prospectFilter.includes(prospectStatus);
};

export const canAddFundPurpose = (item, fundPurpose) => {
  if (fundPurpose.length < 1) return true;
  if (!item) return false;
  if (item.data.nodeType !== nodeType.Fund) return true;
  return fundPurpose.includes(item.data.purpose);
};

export const canAddRegion = (item, regionFilters, isRegionsFilterEmpty) => {
  if (!item) return false;
  if (isRegionsFilterEmpty) return true;
  return regionFilters[item.data.MetroRegion] !== undefined;
};

export const canAddDegrees = (item, degree) => {
  if (!item) return false;
  if (!degree || degree >= 3) return true;
  if (item.traversal === undefined) return false;
  return item.traversal <= degree;
};

export const canAddAffiliation = (item, affiliation) => {
  if (!item) return false;
  if (!affiliation) return true;
  if (item.data.nodeType !== nodeType.Person) return false;
  return (
    item?.data?.affliationUnit &&
    item?.data?.affliationUnit.toLowerCase().includes(affiliation)
  );
};
export const canAddConstituency = (
  item,
  constitencies,
  isConstituencyEmpty,
) => {
  if (!item) return false;
  if (isConstituencyEmpty) return true;
  if (
    item.data.nodeType !== nodeType.Person &&
    item.data.nodeType !== nodeType.Organization
  )
    return false;
  const constit = item?.data?.constituency.toLowerCase();
  let y = false;
  constitencies.forEach((x) => {
    if (constit.includes(x.toLowerCase())) {
      y = true;
    }
  });
  return y;
};

export const canAddTotalConnections = (item, connections) => {
  if (!item) return false;
  if (connections < 1 || connections >= totalConnectionsFilterMax) return true;
  return item.data.totalConnections >= connections;
};

export const canAddDeceased = (item, showDeceased) => {
  if (!item) return false;
  if (item.data.nodeType !== nodeType.Person || showDeceased) return true;
  return !item.data.isdeceased;
};

export const canAddTotalLifetimeGiving = (item, range) => {
  if (!item) return false;
  const { min, max } = range;
  if (min === undefined && max === undefined) {
    return true;
  }
  if (
    item.data.nodeType !== nodeType.Person &&
    item.data.nodeType !== nodeType.Organization
  ) {
    return false;
  }
  const {
    data: { lifetimeGiving },
  } = item;
  if (min !== undefined && lifetimeGiving < min) {
    return false;
  }
  if (max !== undefined && lifetimeGiving > max) {
    return false;
  }
  return true;
};

export const canAddAge = (item, ageFilter) => {
  if (!item) return false;
  const { start, end } = ageFilter;
  if (start === undefined && end === undefined) {
    return true;
  }
  if (item.data.nodeType !== nodeType.Person) {
    return true;
  }
  const {
    data: { age },
  } = item;
  if (start !== undefined && age < start) {
    return false;
  }
  if (end !== undefined && age > end) {
    return false;
  }
  return true;
};
