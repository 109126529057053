import {
  CANCEL_SEARCH,
  FEEBACK_MODAL,
  FEEDBACK_ERROR,
  RESET_COMPLETE_GRAPH,
  SEND_FEEDBACK,
  SETUP_SIGNALR_CONNECTION,
  SET_CONNECTION,
  SET_TOKEN,
  INDIVDUAL_FILTERS,
} from './constants';

export const setToken = (token, expires, username) => {
  return {
    type: SET_TOKEN,
    token,
    expires,
    username,
  };
};

export const setConnection = (connection) => {
  return {
    type: SET_CONNECTION,
    connection,
  };
};

export const setupConnection = (token) => {
  return {
    type: SETUP_SIGNALR_CONNECTION,
    token,
  };
};

export const sendFeedback = (request) => {
  return {
    type: SEND_FEEDBACK,
    payload: request,
  };
};

export const showFeedbackModal = (option, imageUrl) => {
  return {
    type: FEEBACK_MODAL,
    payload: true,
    option,
    imageUrl,
  };
};

// set success to first show success before hiding
export const hideFeedbackModal = (success = false) => {
  return {
    type: FEEBACK_MODAL,
    payload: success,
  };
};

export const setFeedBackError = (payload) => {
  return {
    type: FEEDBACK_ERROR,
    payload,
  };
};

export const resetCompleteGraph = () => {
  return {
    type: RESET_COMPLETE_GRAPH,
  };
};

export const cancelSearch = (payload = false) => {
  return {
    type: CANCEL_SEARCH,
    payload,
  };
};
export const setShowIndividualFilters = (payload = false) => {
  return {
    type: INDIVDUAL_FILTERS,
    payload,
  };
};
