import React from 'react';
import PropTypes from 'prop-types';
import { BsChevronRight } from 'react-icons/bs';
import CardHeader from '../../card-header';
import Input from '../../input';
import styles from '../styles/person-relationship-card.module.css';
import Img from 'common/img-container';
import TypesBar from 'common/types-bar';
import { currencyFormatter } from 'utils/formatters/currency';

const Person = ({ properties, headerText }) => {
  const {
    name,
    imgSrc,
    maiden,
    age,
    region,
    prospectStatus,
    lifetimeGiving,
    alum,
    activelymanaged,
    donor,
    isdeceased,
  } = properties;

  const formatted = currencyFormatter(lifetimeGiving);
  return (
    <div className={styles.card}>
      <CardHeader value={headerText} />
      <div className={styles.content}>
        <div className={styles.content_header}>
          <div className={styles.left_section}>
            <div className={styles.name}>
              <h2 className={styles.name_desc}>{name}</h2>
              <BsChevronRight className={styles.name_icon} />
            </div>
            <div className={styles.person_details_wrapper}>
              <div className={styles.person_details_title}>Age:</div>
              <div
                className={styles.person_details_value}
                style={{ width: '30px' }}
              >
                {age}
              </div>
              {isdeceased && <div className={styles.deceased}>(Deceased)</div>}
            </div>
            <div className={styles.person_details_wrapper}>
              <div className={styles.person_details_title}>Maiden:</div>
              <div className={styles.person_details_value}>{maiden}</div>
            </div>
            <div className={styles.person_details_wrapper}>
              <div className={styles.person_details_title}>Region:</div>
              <div className={styles.person_details_value}>{region}</div>
            </div>
          </div>
          <div className={styles.right_section}>
            <Img src={imgSrc} size="64px" />
          </div>
        </div>
        <div className={styles.types}>
          <TypesBar
            showAlumni={alum === 1}
            showManaged={activelymanaged}
            showDonor={donor === 1}
            iconColor="#38B2D9"
          />
        </div>
        <div className={styles.giving_prospect_status}>
          <div className={styles.split_wrapper}>
            <Input value={formatted} label="Lifetime giving" />
          </div>
          <div className={styles.split_wrapper}>
            <Input value={prospectStatus} label="Prospect status" />
          </div>
        </div>
      </div>
    </div>
  );
};

Person.propTypes = {
  properties: PropTypes.object,
};

export default Person;
