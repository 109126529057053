export const createQuery = (contexts, filters, traversals) => {
  const query = {
    context: contexts,
    filters: [
      {
        type: 'edge',
        label: '',
        direction: 'both',
        filterValue: null,
        condition: 'none',
        filters: [],
      },
    ],
    traversals,
  };
  return query;
};

export const getNextMinTraversal = (contextsMap, contextToIgnore) => {
  let min = 3;
  for (const contextId in contextsMap) {
    if (contextsMap[contextId] < min) {
      min = contextsMap[contextId];
    }
  }

  return min;
};

export const readBlob = async (blob, isNlQuery = false) => {
  try {
    const response = await fetch(blob);
    const jsonResponse = await response.json();
    if (isNlQuery) {
      return {
        edges: jsonResponse.graph.Edges,
        vertices: jsonResponse.graph.Vertices,
        responseGenerated: jsonResponse.graph.ResponseGenerated,
      };
    }
    return {
      edges: jsonResponse.Edges,
      vertices: jsonResponse.Vertices,
      responseGenerated: jsonResponse.ResponseGenerated,
    };
  } catch (error) {
    console.error('Error reading blob', error);
    return null;
  }
};
