import React, { useRef, useCallback, useState, useEffect } from 'react';
import styles from './sidebar.module.css';
import ReactTooltip from 'react-tooltip';
import { Footer } from './footer';
import ContextInputs from 'common/context-input';
import SearchInput from 'common/search-input';
import content_styles from './content.module.css';
import FilterBy from 'common/filters/filter-by';
import { useSelector } from 'react-redux';
import { selectConnection } from 'store/core/selectors';
import { getDataDate } from 'store/core/connection';

export const Sidebar = ({ isCollapsed, setIsCollapsed, rootRef }) => {
  const sidebarRef = useRef(null);
  const conn = useSelector(selectConnection);
  const [dataDate, setDate] = useState(undefined);

  useEffect(() => {
    if (conn) {
      setTimeout(() => {
        const date = getDataDate();
        setDate(new Date(date).toLocaleDateString());
      }, 2000);
    }
  }, [conn]);

  const arrowHoverValue = isCollapsed ? 'Open sidebar' : 'Close sidebar';
  const ArrowButton = useCallback(() => {
    return (
      <div
        className={styles.pin_icon}
        onClick={() => {
          setIsCollapsed(!isCollapsed);
        }}
        data-tip
        data-for="arrow-button-id"
      >
        <div
          className={`${styles.pin_icon1} ${
            isCollapsed ? styles.collapsed_p1 : styles.p1
          }`}
        ></div>
        <div
          className={`${styles.pin_icon2} ${
            isCollapsed ? styles.collapsed_p2 : styles.p2
          }`}
        ></div>
        <ReactTooltip id="arrow-button-id" place="top" effect="solid">
          {arrowHoverValue}
        </ReactTooltip>
      </div>
    );
  }, [isCollapsed]);

  const innerComponentRef = useRef(null);
  const outerRef = useRef(null);
  const isDev = process.env.REACT_APP_ENVIRONMENT === 'development';

  return (
    <div
      ref={sidebarRef}
      className={`${styles.root} ${!isCollapsed && styles.root__pinned} ${
        isCollapsed && styles.collapsed_root
      }  `}
    >
      <div
        className={`${
          isCollapsed
            ? styles.collapsed_body
            : styles.not_collapsed_body__pinned
        }`}
      >
        <ArrowButton />
        {!isCollapsed && (
          <div className={styles.sidebar_menu}>
            <div className={styles.not_collapsed_body_header}>
              <span className={styles.not_collapsed_body_header_text}>
                Foundation Tool
              </span>
              {isDev && <span className={styles.dev_text}>Dev</span>}
            </div>
            <div className={styles.content} ref={outerRef}>
              <div
                style={{ height: '100%', width: '100%' }}
                ref={innerComponentRef}
              >
                <NavigationContent />
              </div>
            </div>
            <Footer
              isCollapsed={isCollapsed}
              dataDate={dataDate}
              rootRef={rootRef}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export const NavigationContent = () => {
  return (
    <>
      <div className={content_styles.search_wrapper}>
        <ContextInputs />
        <SearchInput />
      </div>
      <div>
        <FilterBy />
      </div>
    </>
  );
};
