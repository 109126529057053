import React, { useEffect, useState } from 'react';
import styles from './feedmodal.module.css';
import { IoCloseOutline } from 'react-icons/io5';
import { useDispatch, useSelector } from 'react-redux';
import { sendFeedback } from 'store/core/actions';
import { selectFeedbackOption } from 'store/core/selectors';

const NewFeedbackModal = ({ onClose, isLoading, setIsLoading }) => {
  const [tags, setTags] = useState([]);
  const dispatch = useDispatch();
  const feedbackOption = useSelector(selectFeedbackOption);
  const [title, setTitle] = useState('');
  const [details, setDetails] = useState('');
  const [titleError, setTitleError] = useState('');
  const [detailsError, setDetailsError] = useState('');
  const [selectedButton, setSelectedButton] = useState(0);
  const maxTags = 3;
  const predefinedTags = [
    'User Experience',
    'UI Design',
    'Incomplete Functionality',
    'Other',
  ];

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };

  const handleDetailsChange = (e) => {
    setDetails(e.target.value);
  };

  const handleAddTag = (tag) => {
    if (tags.length < maxTags && !tags.includes(tag)) {
      setTags([...tags, tag]);
    }
  };

  const handleRemoveTag = (tag) => {
    setTags(tags.filter((t) => t !== tag));
  };

  const onSubmit = () => {
    setIsLoading(true);
    if (!title) {
      setTitleError('Please enter a title!');
      setIsLoading(false);
      return;
    } else {
      setTitleError('');
    }

    var _navigator = {};
    for (var i in window.navigator) _navigator[i] = navigator[i];
    const systemInfo = JSON.stringify(_navigator);
    const request = {
      title,
      details,
      systemInfo,
    };
    dispatch(sendFeedback(request));
    onClose();
  };

  useEffect(() => {
    if (feedbackOption) {
      setTitle(
        feedbackOption ? 'Great Graph Generated' : 'Incorrect Graph Generated',
      );
      setDetails(
        feedbackOption
          ? 'The graph generated was great and accurate to the query'
          : 'The graph generated was incorrect and did not match the query',
      );
    }
  }, [feedbackOption]);

  return (
    <div className={styles.modal_overlay}>
      <div className={styles.modal_container}>
        <div className={styles.modal_header}>
          <h2 className={styles.modal_title}>Help Us Improve!</h2>
          <button className={styles.close_button} onClick={onClose}>
            <IoCloseOutline />
          </button>
        </div>

        <div className={styles.modal_content}>
          <div className={styles.buttons}>
            <div
              className={`${styles.button_container} ${
                selectedButton === 0 ? styles.button_container_selected : ''
              }`}
              onClick={() => setSelectedButton(0)}
            >
              <div className={styles.button_icon}>🐛</div>
              <div>
                <div className={styles.button_title}>Issue</div>
                <div className={styles.button_description}>
                  Report a bug or issue
                </div>
              </div>
            </div>
            <div
              className={`${styles.button_container} ${
                selectedButton === 1 ? styles.button_container_selected : ''
              }`}
              onClick={() => setSelectedButton(1)}
            >
              <div className={styles.button_icon}>💡</div>
              <div>
                <div className={styles.button_title}>New Feature Request</div>
                <div className={styles.button_description}>
                  Create new functionality
                </div>
              </div>
            </div>
          </div>
          <div className={styles.upload_area}>
            <div className={styles.input_container_wrapper}>
              <label className={styles.tags_label}>Title</label>
              <input
                className={styles.other_input_container}
                onChange={handleTitleChange}
                value={title}
                placeholder="Enter title. Ex: Issue in application"
              />
              {titleError && <div className={styles.error}>{titleError}</div>}
            </div>
          </div>

          <div className={styles.upload_area}>
            <div className={styles.input_container_wrapper}>
              <label className={styles.tags_label}>Details</label>
              <textarea
                className={styles.other_input_container}
                onChange={handleDetailsChange}
                value={details}
                placeholder="Enter feedback or describe & if possible, provide reproduction steps for any issues"
              />
              {detailsError && (
                <div className={styles.error}>{detailsError}</div>
              )}
            </div>
          </div>

          <div className={styles.tags_section}>
            <div className={styles.tags_header}>
              <span className={styles.tags_label}>Add tags</span>
              <span className={styles.tags_counter}>
                {maxTags - tags.length} tags remaining
              </span>
            </div>

            {tags.length > 0 && (
              <div className={styles.tags_input_container}>
                <div className={styles.tags_container}>
                  {tags.map((tag, index) => (
                    <div key={index} className={styles.tag}>
                      {tag}
                      <button
                        className={styles.tag_remove}
                        onClick={() => handleRemoveTag(tag)}
                      >
                        ×
                      </button>
                    </div>
                  ))}
                </div>
              </div>
            )}

            <div className={styles.predefined_tags}>
              {predefinedTags.map((tag, index) => (
                <button
                  key={index}
                  className={styles.predefined_tag}
                  onClick={() => handleAddTag(tag)}
                >
                  {tag}
                </button>
              ))}
            </div>
          </div>
        </div>

        <div className={styles.modal_footer}>
          <button className={styles.save_draft_button} onClick={onClose}>
            Cancel
          </button>
          <div className={styles.footer_right}>
            <button className={styles.share_button} onClick={onSubmit}>
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewFeedbackModal;
