import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import RegionFilter from '../region-filter';
import AfilliationFilter from '../affiliation-unit';
import ProspectFilter from '../prospect-filter';
import styles from './filterBy.module.css';
import LifetimeGivingFilter from '../lifetime-giving';
import TotalConnectionsFilter from '../total-connections';
import DegreesOfSeparationFilter from '../degrees-separation';
import ConstituencyTypeFilter from '../constituency-type';
// import TagsFilter from '../tags';
import '../styles.css';
import { clearNodeFilters } from 'store/filters/actions';
import NodeSizing from '../node-sizing';
import { setView } from 'store/graph/actions';
import ReactTooltip from 'react-tooltip';
import { selectHiddenNodes } from 'store/filters/selectors';
import HiddenNodesFilter from '../hidden-nodes';
import ShowDeceasedFilter from '../show-deceased';
import AgeFilter from '../age-filter';
import FundPurposeFilter from '../fund-purpose-filter';

const FilterBy = () => {
  const dispatch = useDispatch();
  const hiddenNodes = useSelector(selectHiddenNodes);
  const hiddenNodeIds = Object.keys(hiddenNodes);
  const clear = (e) => {
    if (e && e.preventDefault) e.preventDefault();
    dispatch(clearNodeFilters());
  };
  const view = (e) => {
    if (e && e.preventDefault) e.preventDefault();
    dispatch(setView());
  };
  return (
    <div className={styles.wrapper}>
      <div className={styles.header_wrapper}>
        <p className={styles.search_header} onClick={() => view()}>
          Filter By
        </p>
        <div
          className={styles.header_type}
          onClick={clear}
          data-for="clear-map-node-filters"
          data-tip
        >
          Clear All
        </div>
        <ReactTooltip id="clear-map-node-filters" place="right" effect="solid">
          <div style={{ maxWidth: '110px' }}>
            This action will reset all Filters
          </div>
        </ReactTooltip>
      </div>
      <div className={styles.header_row}>
        <p className={styles.search_header}>Map Filters</p>
      </div>
      <AfilliationFilter />
      <ProspectFilter />
      <FundPurposeFilter />
      <LifetimeGivingFilter />
      <ConstituencyTypeFilter />
      <TotalConnectionsFilter />
      <RegionFilter />
      <NodeSizing />
      <ShowDeceasedFilter />
      <AgeFilter />
      <div className={styles.header_row}>
        <p className={styles.search_header}>Node Filters</p>
      </div>
      <DegreesOfSeparationFilter />
      {hiddenNodeIds.length > 0 && (
        <HiddenNodesFilter nodes={hiddenNodes} hiddenNodeIds={hiddenNodeIds} />
      )}
    </div>
  );
};

export default FilterBy;
