import { UNSELECTED_STATE } from 'store/filters/constants';

export const familyOptions = {
  'Parent-Child': {
    isSelected: UNSELECTED_STATE,
  },
  'Grandparent-Grandchild': {
    isSelected: UNSELECTED_STATE,
  },
  'Aunt/Uncle-Niece/Nephew': {
    isSelected: UNSELECTED_STATE,
  },
  Cousin: {
    isSelected: UNSELECTED_STATE,
  },
  'In-law': {
    isSelected: UNSELECTED_STATE,
  },
  Relative: {
    isSelected: UNSELECTED_STATE,
  },
  Sibling: {
    isSelected: UNSELECTED_STATE,
  },
  'Spouse/Partner': {
    isSelected: UNSELECTED_STATE,
  },
  'Step Relative': {
    isSelected: UNSELECTED_STATE,
  },
};

export const customFamilyGroupingOptions = {
  'Immediate Family': {
    isSelected: UNSELECTED_STATE,
  },
  'Extended Family': {
    isSelected: UNSELECTED_STATE,
  },
};

export const FamilyGroupMapping = {
  'Extended Family': [
    'Relative',
    'Step Relative',
    'In-law',
    'Cousin',
    'Aunt/Uncle-Niece/Nephew',
    'Grandparent-Grandchild',
  ],
  'Immediate Family': ['Spouse/Partner', 'Sibling', 'Parent-Child'],
};

export const professionalOptions = {
  Friend: {
    isSelected: UNSELECTED_STATE,
  },
  Acquaintance: {
    isSelected: UNSELECTED_STATE,
  },
  Neighbor: {
    isSelected: UNSELECTED_STATE,
  },
  Roommate: {
    isSelected: UNSELECTED_STATE,
  },
  'Mentor-Mentee': {
    isSelected: UNSELECTED_STATE,
  },
};

export const organizationalOptions = {
  'Employer-Employee': {
    isSelected: UNSELECTED_STATE,
  },
  Colleague: {
    isSelected: UNSELECTED_STATE,
  },
  'Employer-Postdoctoral Researcher': {
    isSelected: UNSELECTED_STATE,
  },
  'Service Provider-Client': {
    isSelected: UNSELECTED_STATE,
  },
  Collaborator: {
    isSelected: UNSELECTED_STATE,
  },
  'Instructor/Advisor-Student': {
    isSelected: UNSELECTED_STATE,
  },
  'Postdoctoral Supervisor-Postdoctoral Researcher': {
    isSelected: UNSELECTED_STATE,
  },
  'Power of Attorney-Client': {
    isSelected: UNSELECTED_STATE,
  },
  'Organization-Branch Office': {
    isSelected: UNSELECTED_STATE,
  },
  'Associated Company': {
    isSelected: UNSELECTED_STATE,
  },
  'Successor Company-Defunct Company': {
    isSelected: UNSELECTED_STATE,
  },
  'Joint Venture': {
    isSelected: UNSELECTED_STATE,
  },
  'Prospect-Interaction Owner': {
    isSelected: UNSELECTED_STATE,
  },
  'Prospect-Prospect Manager': {
    isSelected: UNSELECTED_STATE,
  },
  'Prospect-Primary Plan Manager': {
    isSelected: UNSELECTED_STATE,
  },
  'Prospect-Secondary Solicitor': {
    isSelected: UNSELECTED_STATE,
  },
  'Principal Investigator-Grant': {
    isSelected: UNSELECTED_STATE,
  },
  'Prospect-Active Plan Manager': {
    isSelected: UNSELECTED_STATE,
  },
};

export const charitableOptions = {
  'Donor-Fund': {
    isSelected: UNSELECTED_STATE,
  },
  'Donor-Matching Organization': {
    isSelected: UNSELECTED_STATE,
  },
  'Donor-Credit Organization': {
    isSelected: UNSELECTED_STATE,
  },
  'Donor-Organization': {
    isSelected: UNSELECTED_STATE,
  },
};

export const educationalOptions = {
  'Student-UI Education': {
    isSelected: UNSELECTED_STATE,
  },
  'Organization-UI Education': {
    isSelected: UNSELECTED_STATE,
  },
  'Student-External Education': {
    isSelected: UNSELECTED_STATE,
  },
  'Organization-External Education': {
    isSelected: UNSELECTED_STATE,
  },
};

export const engagementOptions = {
  'Organization-Leader': {
    isSelected: UNSELECTED_STATE,
  },
  'Organization-Member': {
    isSelected: UNSELECTED_STATE,
  },
  'Organization-Staff': {
    isSelected: UNSELECTED_STATE,
  },
};

export const nodeOptions = {
  Person: {
    isSelected: UNSELECTED_STATE,
  },
  Organization: {
    isSelected: UNSELECTED_STATE,
  },
  Education: {
    isSelected: UNSELECTED_STATE,
  },
  Fund: {
    isSelected: UNSELECTED_STATE,
  },
  Engagement: {
    isSelected: UNSELECTED_STATE,
  },
};

let edgeOptions;
export const EdgeOptionsSetup = () => {
  edgeOptions = new EdgeFilterOptions();
};

export const GetEdgeOptions = () => {
  return edgeOptions;
};

export class EdgeFilterOptions {
  constructor() {
    if (!EdgeFilterOptions.instance) {
      this.nodeOptions = nodeOptions;
      this.engagementOptions = engagementOptions;
      this.educationalOptions = educationalOptions;
      this.charitableOptions = charitableOptions;
      this.organizationalOptions = organizationalOptions;
      this.professionalOptions = professionalOptions;
      this.familyOptions = familyOptions;

      EdgeFilterOptions.instance = this;
    }

    return EdgeFilterOptions.instance;
  }

  getNodeOptions() {
    return { ...nodeOptions };
  }

  getEngagementOptions() {
    return { ...engagementOptions };
  }

  getEducationalOptions() {
    return { ...educationalOptions };
  }

  getCharitableOptions() {
    return { ...charitableOptions };
  }

  getOrganizationalOptions() {
    return { ...organizationalOptions };
  }

  getProfessionalOptions() {
    return { ...professionalOptions };
  }

  getFamilyOptions() {
    return { ...familyOptions };
  }

  getAllOptions() {
    return {
      nodeOptions: this.nodeOptions,
      engagementOptions: this.engagementOptions,
      educationalOptions: this.educationalOptions,
      charitableOptions: this.charitableOptions,
      organizationalOptions: this.organizationalOptions,
      professionalOptions: this.professionalOptions,
      familyOptions: this.familyOptions,
    };
  }
}
