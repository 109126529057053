import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import Loading from 'common/chart/loading';
import ReGraphChartWrapper from 'common/chart';
import FilterCards from 'common/edge-filter-cards';
import { selectHasAiContext, selectIsLoading } from 'store/graph/selectors';
import styles from './home.module.css';
import FeedbackModel from 'common/feeback_modal';
import {
  selectShowFeedbackModal,
  selectShowIndividualFilters,
} from 'store/core/selectors';
import { Sidebar } from 'common/sidebar';
import ContextFiltersModal from 'common/context-filters-modal';
import SelectedDetailNode from './selectedDetailNode';
import SelectedEdge from './selectedEdge';
import Chat from 'common/chat';
import AIFeedback from './ai_feedback';

const Home = () => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const rootRef = useRef(null);
  const [showChat, setShowChat] = useState(false);
  const showFeedbackModal = useSelector(selectShowFeedbackModal);
  const isLoading = useSelector(selectIsLoading);
  const showIndividualFilters = useSelector(selectShowIndividualFilters);
  const hasAiContext = useSelector(selectHasAiContext);

  useEffect(() => {
    const handleKeyPress = (e) => {
      if (e.ctrlKey && e.key.toLowerCase() === '/') {
        e.preventDefault();
        setShowChat((prev) => !prev);
      }
    };

    window.addEventListener('keydown', handleKeyPress);
    return () => window.removeEventListener('keydown', handleKeyPress);
  }, []);

  return (
    <div ref={rootRef} className={styles.root}>
      <Sidebar
        isCollapsed={isCollapsed}
        setIsCollapsed={setIsCollapsed}
        rootRef={rootRef}
      />
      <div
        className={`${styles.right_section} ${
          isCollapsed && styles.full_width
        }`}
        style={{
          width: isCollapsed ? '100%' : 'calc(100% - 300px)',
        }}
      >
        <div className={styles.filter_card_wrapper}>
          <FilterCards />
        </div>
        <SelectedEdge />
        <SelectedDetailNode />
        {isLoading ? <Loading /> : <ReGraphChartWrapper />}
        {showChat && <Chat onClose={() => setShowChat(false)} />}
        {hasAiContext && <AIFeedback />}
      </div>
      {showFeedbackModal && <FeedbackModel />}
      {showIndividualFilters && <ContextFiltersModal />}
    </div>
  );
};

export default Home;
