import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setAgeFilter } from 'store/filters/actions';
import DropDown from '../../drop-down';
import styles from '../lifetime-giving/lifetime.module.css';
import { selectAgeFilter } from 'store/filters/selectors';
import debounceFunction from 'common/search-input/debounce';
import ReactTooltip from 'react-tooltip';
import { validateNumber } from '../lifetime-giving';

const AgeFilter = ({
  maxLocal = undefined,
  minLocal = undefined,
  useLocal = false,
  setMinLocal = undefined,
  setMaxLocal = undefined,
}) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const age = useSelector(selectAgeFilter);
  const { start, end } = age;
  const [minAgeEdit, setMinAgeEdit] = useState(false);
  const [maxAgeEdit, setMaxAgeEdit] = useState(false);
  const [error, setError] = useState('');
  const handleToggle = () => {
    setOpen(!open);
  };

  const setMinAgeEditTrue = () => {
    setMinAgeEdit(true);
  };
  const stopMinAgeEdit = () => {
    setMinAgeEdit(false);
    setError('');
  };

  const setMaxValue = (value) => {
    if (useLocal) {
      setMaxLocal(value);
    } else dispatch(setAgeFilter(null, value));
  };

  const setMaxAgeEditTrue = () => {
    setMaxAgeEdit(true);
  };
  const stopMaxAgeEdit = () => {
    setMaxAgeEdit(false);
    setError('');
  };
  const setMinValue = (value) => {
    if (useLocal) {
      setMinLocal(value);
    } else dispatch(setAgeFilter(value, null));
  };

  return (
    <div className={!open ? 'filter_by_wrapper' : 'filter_by_open_wrapper'}>
      <DropDown filterText="Age" onClick={handleToggle} open={open} />
      {open && (
        <div className={styles.open_region}>
          <div className={styles.input_region}>
            <AgeEditInput
              title="Min"
              min={useLocal ? minLocal : start}
              max={useLocal ? maxLocal : end}
              isEdit={minAgeEdit}
              setEditTrue={setMinAgeEditTrue}
              close={stopMinAgeEdit}
              setValue={setMinValue}
              setError={setError}
            />
            <AgeEditInput
              title="Max"
              min={useLocal ? minLocal : start}
              max={useLocal ? maxLocal : end}
              isEdit={maxAgeEdit}
              setEditTrue={setMaxAgeEditTrue}
              close={stopMaxAgeEdit}
              setValue={setMaxValue}
              setError={setError}
            />
          </div>
          <div className={styles.error}>{error}</div>
        </div>
      )}
    </div>
  );
};

export default AgeFilter;

const AgeEditInput = ({
  title,
  max,
  min,
  setValue,
  isEdit,
  setEditTrue,
  close,
  setError,
}) => {
  const value = title === 'Min' ? min : max;
  const [inputValue, setInputValue] = useState(value);
  const handleSave = (val) => {
    if (val === '') {
      setValue(undefined);
      setInputValue('');
      setError('');
      close();
    } else {
      const validNumber = validateNumber(val);
      if (!validNumber) {
        setError('Please enter in a valid number!');
      } else {
        setValue(validNumber);
        setInputValue('');
        setError('');
        close();
      }
    }
  };

  const setDebouncedValue = (val) => {
    handleSave(val);
  };

  const debounceDropDown = useCallback(
    debounceFunction((nextValue) => setDebouncedValue(nextValue), 1000),
    [],
  );

  const onChange = (e) => {
    if (e && e.preventDefault) e.preventDefault();
    const value = e.target.value;
    setInputValue(value);
    debounceDropDown(value);
  };

  const handleBlur = () => {
    if (inputValue === '' || inputValue === value) {
      close();
    }
  };

  const handleFocus = () => {
    setInputValue(value);
  };

  let content = (
    <>
      <p className={styles.option}>{title}</p>
      <div className={styles.input} data-tip data-for={`liftime-id-${title}`}>
        {value}
      </div>
      {value && (
        <ReactTooltip id={`liftime-id-${title}`} place="top" effect="solid">
          {value}
        </ReactTooltip>
      )}
    </>
  );
  if (isEdit) {
    content = (
      <>
        <input
          autoFocus
          type="number"
          className={styles.input_edit}
          value={inputValue}
          onChange={onChange}
          onBlur={handleBlur}
          onFocus={handleFocus}
        />
      </>
    );
  }

  return (
    <div className={styles.input_wrapper} onClick={setEditTrue}>
      {content}
    </div>
  );
};
