import { useDispatch, useSelector } from 'react-redux';
import { hideFeedbackModal, showFeedbackModal } from 'store/core/actions';
import { BsQuestionCircleFill } from 'react-icons/bs';
import { FaCheckCircle } from 'react-icons/fa';
import styles from './feedback_modal.module.css';
import { useEffect, useState } from 'react';
import {
  selectFeedbackError,
  selectShowFeedbackModal,
} from 'store/core/selectors';
import NewFeedbackModal from './new_feedback_modal';

export const FeedbackIcon = () => {
  const dispatch = useDispatch();
  const openFeedbackModal = () => {
    dispatch(showFeedbackModal());
  };
  return (
    <div className={styles.feedback_button} onClick={openFeedbackModal}>
      <BsQuestionCircleFill />
    </div>
  );
};

export const FeedbackModel = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const feedbackError = useSelector(selectFeedbackError);
  const showSuccessMessage = useSelector(selectShowFeedbackModal);

  const close = () => {
    dispatch(hideFeedbackModal());
  };

  useEffect(() => {
    if (feedbackError) {
      setIsLoading(false);
    }
    if (showSuccessMessage === 'success') {
      setTimeout(close, 1200);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [feedbackError, showSuccessMessage]);

  let content = (
    <NewFeedbackModal
      onClose={close}
      isLoading={isLoading}
      setIsLoading={setIsLoading}
    />
  );
  if (showSuccessMessage === 'success') {
    content = (
      <div className={styles.card_success}>
        <div className={styles.success_content}>
          <FaCheckCircle className={styles.success_icon} />
          <div>We have recieved you're feedback!</div>
        </div>
      </div>
    );
  }

  return <div className={styles.root}>{content}</div>;
};

export default FeedbackModel;
