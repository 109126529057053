import { put, select, call } from 'redux-saga/effects';
import { selectConnection } from 'store/core/selectors';
import {
  removeGraphItem,
  setGraphItem,
  setIsLoadingGraphItems,
} from 'store/graph/actions';
import { selectContextsObject } from 'store/graph/selectors';
import { getItems } from 'utils/graph-helper';
import { readBlob } from 'utils/query-helper';

const nlQuery = async (connection, action) => {
  const { message, contexts } = action;
  // console.log(message, contexts);
  const res = await connection.invoke('nl_query', message, contexts);
  if (res) {
    return await readBlob(res, true);
  }
  return res;
};

export function* nlQuerySaga(action) {
  yield put(setIsLoadingGraphItems(true));
  const contexts = yield select(selectContextsObject);
  const connection = yield select(selectConnection);

  try {
    const response = yield call(nlQuery, connection, action);

    // Handle each context separately
    if (response !== null) {
      if (action.contexts.length === 0 && action.type === 'SET_NL_MESSAGE') {
        const messageContext = {
          id: `nl-message-search-${Date.now()}`,
          displayValue: action.message,
          ai_search: true,
        };
        action.contexts.push(messageContext);
      }
      for (const context of action.contexts) {
        const { nodes, links, nodeCount } = getItems(response, context.id);
        if (nodes != null) {
          if (contexts[context.id]) {
            yield put(removeGraphItem(context.id));
          }
          const ai_context = { ...context, ai_search: true };
          yield put(setGraphItem(ai_context, nodes, links, nodeCount));
        }
      }
    }

    yield put(setIsLoadingGraphItems(false));
  } catch (error) {
    // console.log('Error in nlQuerySaga:', error);
    yield put(setIsLoadingGraphItems(false));
  }
}
