import { createSelector } from 'reselect';

const core = (state) => state.core;

export const selectToken = createSelector([core], (state) => {
  return state.token;
});

export const selectConnection = createSelector([core], (state) => {
  return state.connection;
});

export const selectShowFeedbackModal = createSelector([core], (state) => {
  return state.showFeedbackModal;
});

export const selectFeedbackError = createSelector([core], (state) => {
  return state.feedbackError;
});

export const selectFeedbackOption = createSelector([core], (state) => {
  return state.feedbackOption;
});

export const selectUsername = createSelector([core], (state) => {
  const initials = state.username ? state.username[0] : '';
  return { initials, username: state.username };
});

export const selectCancel = createSelector([core], (state) => {
  return state.cancelSearch;
});

export const selectShowIndividualFilters = createSelector([core], (state) => {
  return state.showIndividualFilters;
});

export const selectFeedbackImageUrl = createSelector([core], (state) => {
  return state.feedbackImageUrl;
});
