import { useDispatch } from 'react-redux';
import styles from './home.module.css';
import { AiOutlineLike, AiOutlineDislike } from 'react-icons/ai';
import { showFeedbackModal } from 'store/core/actions';

export const AIFeedback = () => {
  const dispatch = useDispatch();
  const handleClick = (option) => {
    dispatch(showFeedbackModal(true, option));
  };
  return (
    <div className={styles.detailed_wrapper}>
      <div className={styles.feedback_container}>
        <button
          className={styles.feedback_button}
          onClick={() => handleClick(true)}
        >
          <AiOutlineLike size={20} />
        </button>
        <button
          className={styles.feedback_button}
          onClick={() => handleClick(false)}
        >
          <AiOutlineDislike size={20} />
        </button>
      </div>
    </div>
  );
};

export default AIFeedback;
