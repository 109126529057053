import { HIGHLIGHTED_STATE, SELECTED_STATE } from 'store/filters/constants';
import {
  canAddNode,
  GetNodeSelectionStyles,
  getSize,
  IsObjectEmpty,
} from 'utils/graph-helper';
import {
  contextSpecificFilters,
  isEdgeIncluded,
  addNode,
} from 'utils/graph-item-helper';

export const selectItemsHelper = (
  nodes,
  links,
  contextIds,
  filtersForEdges,
  edgeFilterType,
  nodeOptions,
  nodeFilterType,
  regions,
  nodeFilters,
  nodeTypeFilterOut,
  shortestPathNodeid = '',
  timeFilters,
  contexts,
  hiddenNodes,
  contextFilters,
) => {
  let items = {};
  let nodesAlreadyStyled = {};
  // add context nodes
  const ids = Object.keys(contextIds);
  let p2pIds = [];

  ids.forEach((x) => {
    if (x.includes('p2p')) {
      p2pIds.push(contexts[x]);
    } else if (!x.includes('nl-message-search')) {
      const { data, ...nodeToAdd } = nodes[x]?.node;
      items[x] = nodeToAdd;
      nodesAlreadyStyled[x] = x;
    }
  });

  contextSpecificFilters(
    items,
    nodes,
    links,
    hiddenNodes,
    contexts,
    contextFilters,
  );

  const { edgeFilters, removeEdges } = filtersForEdges;
  const nodeFiltersEnabled =
    nodeOptions.length > 0 || nodeTypeFilterOut.length > 0;
  const isRegionsFilterEmpty = IsObjectEmpty(regions);
  const constitencies = Object.keys(nodeFilters.constituency);
  const nodeSizingType = nodeFilters.nodeSizingType;
  const isConstituenciesEmpty = constitencies.length < 1;

  // add nodes from links
  for (const linkId in links) {
    const graphLink = links[linkId]?.link;
    if (
      (removeEdges.length > 0 &&
        isEdgeIncluded(graphLink, removeEdges, timeFilters)) ||
      items[linkId] !== undefined
    ) {
      continue;
    }

    const [nodeOneId, nodeTwoId] = linkId.split('/p/');
    const nodeOne = nodes[nodeOneId]?.node;
    const nodeTwo = nodes[nodeTwoId]?.node;
    if (edgeFilters.length < 1) {
      // if no edge filters, show normal node graph
      const addedNodeOne = addNode(
        nodeOne,
        items,
        nodeOneId,
        nodeFiltersEnabled,
        nodeFilterType,
        nodeTypeFilterOut,
        nodeOptions,
        nodeSizingType,
        constitencies,
        isRegionsFilterEmpty,
        isConstituenciesEmpty,
        nodeFilters,
        hiddenNodes,
      );
      const addedNodeTwo = addNode(
        nodeTwo,
        items,
        nodeTwoId,
        nodeFiltersEnabled,
        nodeFilterType,
        nodeTypeFilterOut,
        nodeOptions,
        nodeSizingType,
        constitencies,
        isRegionsFilterEmpty,
        isConstituenciesEmpty,
        nodeFilters,
        hiddenNodes,
      );

      // add link only if both nodes added
      if (addedNodeOne && addedNodeTwo) {
        const { data, ...newLinkToAdd } = graphLink;
        items[linkId] = newLinkToAdd;
      }
    } else {
      if (edgeFilterType === HIGHLIGHTED_STATE) {
        // filterType is selected state
        const isIncluded = isEdgeIncluded(
          graphLink,
          edgeFilters,
          timeFilters,
          true,
        );
        if (isIncluded) {
          const addedNodeOne = addNode(
            nodeOne,
            items,
            nodeOneId,
            nodeFiltersEnabled,
            nodeFilterType,
            nodeTypeFilterOut,
            nodeOptions,
            nodeSizingType,
            constitencies,
            isRegionsFilterEmpty,
            isConstituenciesEmpty,
            nodeFilters,
            hiddenNodes,
            nodesAlreadyStyled,
            false,
            true,
          );
          const addedNodeTwo = addNode(
            nodeTwo,
            items,
            nodeTwoId,
            nodeFiltersEnabled,
            nodeFilterType,
            nodeTypeFilterOut,
            nodeOptions,
            nodeSizingType,
            constitencies,
            isRegionsFilterEmpty,
            isConstituenciesEmpty,
            nodeFilters,
            hiddenNodes,
            nodesAlreadyStyled,
            false,
            true,
          );

          if (addedNodeOne && addedNodeTwo) {
            const { data, ...newGraphLink } = {
              ...graphLink,
              color: graphLink.activeColor,
            };
            items[linkId] = newGraphLink;
          }
        } else {
          const addedNodeOne = addNode(
            nodeOne,
            items,
            nodeOneId,
            nodeFiltersEnabled,
            nodeFilterType,
            nodeTypeFilterOut,
            nodeOptions,
            nodeSizingType,
            constitencies,
            isRegionsFilterEmpty,
            isConstituenciesEmpty,
            nodeFilters,
            hiddenNodes,
          );
          const addedNodeTwo = addNode(
            nodeTwo,
            items,
            nodeTwoId,
            nodeFiltersEnabled,
            nodeFilterType,
            nodeTypeFilterOut,
            nodeOptions,
            nodeSizingType,
            constitencies,
            isRegionsFilterEmpty,
            isConstituenciesEmpty,
            nodeFilters,
            hiddenNodes,
          );

          if (addedNodeOne && addedNodeTwo) {
            const { data, ...newGraphLink } = graphLink;
            items[linkId] = newGraphLink;
          }
        }
      } else {
        // filterType is selected state
        const isIncluded = isEdgeIncluded(graphLink, edgeFilters, timeFilters);
        if (isIncluded) {
          const addedNodeOne = addNode(
            nodeOne,
            items,
            nodeOneId,
            nodeFiltersEnabled,
            nodeFilterType,
            nodeTypeFilterOut,
            nodeOptions,
            nodeSizingType,
            constitencies,
            isRegionsFilterEmpty,
            isConstituenciesEmpty,
            nodeFilters,
            hiddenNodes,
            true,
          );
          const addedNodeTwo = addNode(
            nodeTwo,
            items,
            nodeTwoId,
            nodeFiltersEnabled,
            nodeFilterType,
            nodeTypeFilterOut,
            nodeOptions,
            nodeSizingType,
            constitencies,
            isRegionsFilterEmpty,
            isConstituenciesEmpty,
            nodeFilters,
            hiddenNodes,
            true,
          );

          if (addedNodeOne && addedNodeTwo) {
            const { data, ...newGraphLink } = {
              ...graphLink,
              color: graphLink.activeColor,
            };
            items[linkId] = newGraphLink;
          }
        }
      }
    }
  }

  // add any left over nodes
  for (const contextId in contexts) {
    const context = contexts[contextId];
    if (
      context !== undefined
      // && context?.value?.id === context?.value?.metroRegion
    ) {
      context?.nodeIds.forEach((x) => {
        const node = nodes[x]?.node;
        if (node !== undefined && items[x] === undefined) {
          if (
            canAddNode(
              node,
              isRegionsFilterEmpty,
              isConstituenciesEmpty,
              constitencies,
              nodeFilters,
              hiddenNodes,
            )
          ) {
            if (!nodeFiltersEnabled) {
              const { data, ...nodeToAdd } = node;
              const size = getSize(nodeSizingType, data);
              items[x] = { ...nodeToAdd, size };
            } else {
              if (nodeFilterType === HIGHLIGHTED_STATE) {
                if (!nodeTypeFilterOut.includes(node.data.nodeType)) {
                  let newNode = node;
                  if (nodeOptions.includes(node.data.nodeType)) {
                    const nodeOneSelectionStyles = GetNodeSelectionStyles(node);
                    newNode = { ...node, ...nodeOneSelectionStyles };
                  }

                  const { data, ...nodeToAdd } = newNode;
                  const size = getSize(nodeSizingType, data);
                  items[x] = { ...nodeToAdd, size };
                }
              } else if (nodeFilterType === SELECTED_STATE) {
                if (
                  nodeOptions.includes(node.data.nodeType) &&
                  canAddNode(
                    node,
                    isRegionsFilterEmpty,
                    isConstituenciesEmpty,
                    constitencies,
                    nodeFilters,
                    hiddenNodes,
                  )
                ) {
                  const nodeOneSelectionStyles = GetNodeSelectionStyles(node);
                  const { data, ...nodeToAdd } = {
                    ...node,
                    ...nodeOneSelectionStyles,
                  };
                  const size = getSize(nodeSizingType, data);
                  items[x] = { ...nodeToAdd, size };
                }
              }
            }
          }
        }
      });
    }
  }

  p2pIds?.forEach((p2pContext) => {
    p2pContext?.nodeIds?.forEach((nodeId) => {
      if (items[nodeId] === undefined) {
        const node = nodes[nodeId]?.node;
        const canAdd = canAddNode(
          node,
          isRegionsFilterEmpty,
          isConstituenciesEmpty,
          constitencies,
          nodeFilters,
          hiddenNodes,
        );
        const size = getSize(nodeSizingType, node?.data);

        if (nodeFiltersEnabled) {
          if (nodeFilterType === HIGHLIGHTED_STATE) {
            if (canAdd && !nodeTypeFilterOut.includes(node.data.nodeType)) {
              let newNode = {};
              if (nodeOptions.includes(node.data.nodeType)) {
                const nodeSelectionStyles = GetNodeSelectionStyles(node);
                newNode = { ...node, ...nodeSelectionStyles };
              } else {
                newNode = node;
              }
              const { data, ...nodeToAdd } = newNode;
              items[nodeId] = { ...nodeToAdd, size };
            }
          } else if (nodeFilterType === SELECTED_STATE) {
            if (nodeOptions.includes(node.data.nodeType) && canAdd) {
              const nodeSelectionStyles = GetNodeSelectionStyles(node);
              const { data, ...nodeTwoAdd } = {
                ...node,
                ...nodeSelectionStyles,
              };
              items[nodeId] = { ...nodeTwoAdd, size };
            } else {
            }
          } else {
            if (canAdd && !nodeTypeFilterOut.includes(node.data.nodeType)) {
              const nodeSelectionStyles = GetNodeSelectionStyles(node);
              const { data, ...nodeTwoAdd } = {
                ...node,
                ...nodeSelectionStyles,
              };
              items[nodeId] = { ...nodeTwoAdd, size };
            }
          }
        } else if (canAdd) {
          const { data, ...nodeTwoAdd } = node;
          items[nodeId] = { ...nodeTwoAdd, size };
        }
      }
    });
  });

  if (shortestPathNodeid) {
    const pathNode = nodes[shortestPathNodeid]?.node;
    if (pathNode === null || pathNode === undefined) {
    } else {
      const newNode = {
        ...pathNode,
        glyphs: [
          {
            size: 1,
            label: {
              text: '-',
              color: '#fff',
            },
          },
        ],
      };
      items[shortestPathNodeid] = newNode;
    }
  }

  // const length = Object.keys(items).length;
  // console.log('nodes-drawn', length);
  return items;
};
