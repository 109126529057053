import { RESET_COMPLETE_GRAPH } from 'store/core/constants';
import {
  REMOVE_GRAPH_ITEMS,
  SET_GRAPH_ITEMS,
  SET_IS_LOADING,
  SET_SELECTED_LINK,
  SET_SELECTED_VERTEX,
  SET_TOP_CONNECTIONS,
  SET_TOP_CONNECTIONS_LOADING,
  SET_SEARCH_CONTEXT,
  SET_MOST_CONNECTED,
  SET_MOST_CONNECTED_LOADING,
} from './constants';
import { getNextMinTraversal } from 'utils/query-helper';
import { GetBaseNodeStyles } from 'utils/graph-helper';
import { REMOVE_NL_MESSAGE, SET_NL_MESSAGE } from 'store/nl/actions';

const oldTraversalBeforeContext = 'oldTraversalBeforeContext';

export const initialState = {
  nodes: {}, // Map<nodeId, {node, contextids: {contextid: degreeOfSeparation}}>
  links: {}, // Map<linkId, {link, contextids: {contextid: contextId}}>
  contexts: {}, // Map<contextId, {value, nodes: [contextItemIds]}>

  graphItems: {},
  contextIds: {},
  topConnections: {},
  topConnectionsLoading: false,
  mostConnected: {},
  mostConnectedLoading: false,

  searchContext: {},
  aiContext: [],
  selectedVertex: undefined,
  selectedEdgeId: undefined,
  isLoading: false,
};

export const graphReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_GRAPH_ITEMS: {
      const { context, newNodes, newLinks, nodeCount } = action;
      const { nodes, links, contexts, contextIds } = state;

      if (nodeCount === 0) {
        const updatedContexts = { ...contexts };
        updatedContexts[context.id] = {
          value: { ...context, nodeCount, id: context.id },
          nodeIds: [],
          linkIds: [],
        };

        return { ...state, contexts: updatedContexts };
      }

      //set New contextId based on type
      let newContextId = context.id;
      const isRegionSearch =
        (context.metroRegion && !context.id) ||
        context.metroRegion === context.id;
      if (isRegionSearch) {
        newContextId = context.metroRegion;
      }

      // set graph nodes
      let updatedNodes = { ...nodes };
      const contextNodeIds = [];

      for (const nodeId in newNodes) {
        contextNodeIds.push(nodeId);
        const item = newNodes[nodeId];
        if (updatedNodes[nodeId]) {
          const currentNode = updatedNodes[nodeId];
          let localContextIds = { ...currentNode.contextIds };
          localContextIds[newContextId] = item.traversal;

          let nodeToAdd = currentNode.node;
          let traversal = currentNode.node?.traversal;
          if (nodeToAdd.traversal > item.traversal) {
            traversal = item?.traversal;
          }

          if (item.isContextNode) {
            const oldValue = currentNode.node.traversal;
            nodeToAdd = item;
            nodeToAdd[oldTraversalBeforeContext] = oldValue;
          }
          nodeToAdd.traversal = traversal;

          updatedNodes[nodeId] = {
            node: nodeToAdd,
            contextIds: localContextIds,
          };
        } else {
          let localContextIds = {};
          localContextIds[newContextId] = item.traversal;
          updatedNodes[nodeId] = { node: item, contextIds: localContextIds };
        }
      }

      // set graph links
      const contextLinkIds = [];
      let updatedLinks = { ...links };
      for (const linkId in newLinks) {
        contextLinkIds.push(linkId);
        const link = newLinks[linkId];
        const currentLink = updatedLinks[linkId];
        if (updatedLinks[linkId]) {
          let localContextIds = { ...currentLink.contextIds };
          localContextIds[newContextId] = newContextId;
          updatedLinks[linkId] = {
            link: currentLink.link,
            contextIds: localContextIds,
          };
        } else {
          let localContextIds = {};
          localContextIds[newContextId] = newContextId;
          updatedLinks[linkId] = { link: link, contextIds: localContextIds };
        }
      }

      // set context
      const updatedContexts = { ...contexts };
      updatedContexts[newContextId] = {
        value: { ...context, nodeCount, id: newContextId },
        nodeIds: contextNodeIds,
        linkIds: contextLinkIds,
      };

      const updatedContextIds = { ...contextIds };
      if (!isRegionSearch) {
        updatedContextIds[newContextId] = newContextId;
      }

      return {
        ...state,
        nodes: updatedNodes,
        links: updatedLinks,
        contexts: updatedContexts,
        contextIds: updatedContextIds,
        searchContext: {},
      };
    }
    case REMOVE_GRAPH_ITEMS: {
      const { contextId } = action;
      const {
        nodes,
        links,
        contexts,
        selectedVertex,
        selectedEdgeId,
        contextIds,
      } = state;
      const nodeIdsToDelete = contexts[contextId].nodeIds;
      const linkIdsToDelete = contexts[contextId].linkIds;

      let currentNodes = { ...nodes };
      let currentLinks = { ...links };
      let updatedContexts = { ...contexts };
      let updatedContextIds = { ...contextIds };
      let updatedSelectedVertexId = selectedVertex;
      let updatedSelectedEdgeId = selectedEdgeId;

      // delete node
      for (const index in nodeIdsToDelete) {
        const id = nodeIdsToDelete[index];
        let nodeToDelete = currentNodes[id];
        if (nodeToDelete) {
          if (Object.keys(nodeToDelete.contextIds).length > 1) {
            delete nodeToDelete.contextIds[contextId];
            const nextTraversal = getNextMinTraversal(
              nodeToDelete.contextIds,
              contextId,
            );
            if (nodeToDelete?.node?.data?.nodeKey === contextId) {
              const baseStyles = GetBaseNodeStyles(
                nodeToDelete.node,
                state.nodeSizingType,
              );
              const updatedStyledNode = {
                ...nodeToDelete.node,
                ...baseStyles,
                traversal: nodeToDelete.node[oldTraversalBeforeContext],
                isContextNode: false,
              };
              currentNodes[contextId] = {
                node: { ...updatedStyledNode },
                contextIds: nodeToDelete.contextIds,
              };
            } else {
              //TODO: reset traversal based on remaining context
              currentNodes[id] = {
                node: { ...nodeToDelete.node, traversal: nextTraversal },
                contextIds: nodeToDelete.contextIds,
              };
            }
          } else {
            if (id === selectedVertex) {
              updatedSelectedVertexId = undefined;
            }
            delete currentNodes[id];
          }
        }
      }

      for (const index in linkIdsToDelete) {
        const linkId = linkIdsToDelete[index];
        let linkToDelete = currentLinks[linkId];
        if (linkToDelete) {
          if (Object.keys(linkToDelete.contextIds).length > 1) {
            delete linkToDelete.contextIds[contextId];
          } else {
            if (linkId === selectedEdgeId) {
              updatedSelectedEdgeId = undefined;
            }
            delete currentLinks[linkId];
          }
        }
      }

      // delete context
      delete updatedContexts[contextId];
      delete updatedContextIds[contextId];

      return {
        ...state,
        nodes: currentNodes,
        links: currentLinks,
        contexts: updatedContexts,
        selectedVertex: updatedSelectedVertexId,
        selectedEdgeId: updatedSelectedEdgeId,
        contextIds: updatedContextIds,
      };
    }
    case SET_NL_MESSAGE:
      return { ...state, aiContext: action.contexts };
    case REMOVE_NL_MESSAGE:
      return { ...state, aiContext: [] };
    case SET_SELECTED_VERTEX:
      return {
        ...state,
        selectedVertex: action.vertex,
        selectedEdgeId: undefined,
      };
    case SET_SELECTED_LINK:
      return {
        ...state,
        selectedEdgeId: action.linkId,
        selectedVertex: undefined,
      };
    case SET_SEARCH_CONTEXT:
      return { ...state, searchContext: action.search };
    case SET_IS_LOADING:
      return { ...state, isLoading: action.value };
    case SET_TOP_CONNECTIONS_LOADING:
      return { ...state, topConnectionsLoading: action.value };
    case SET_TOP_CONNECTIONS:
      const newTopConnections = { ...state.topConnections };
      newTopConnections[action.id] = action.payload;
      return { ...state, topConnections: newTopConnections };
    case SET_MOST_CONNECTED_LOADING:
      return { ...state, mostConnectedLoading: action.value };
    case SET_MOST_CONNECTED:
      const newMostConnected = { ...state.mostConnected };
      newMostConnected[action.id] = action.payload;
      return { ...state, mostConnected: newMostConnected };

    case RESET_COMPLETE_GRAPH:
      return initialState;
    default:
      return state;
  }
};

export default graphReducer;
